import model from './model';
import { FastGalleryService } from '../../services/fastGalleryService';
import { WarmupDataManager } from '../../utils/WarmupDataManager';
import { DraftItem } from '@wix/ambassador-fastgallery-draftitem-v1-draft-item/types';
import { getViewMode } from '../../utils/utils';
import {
  convertDraftItemToWixCodeItem,
  convertDraftItemsToSEOItems,
} from '../../utils/mediaConverters';

export default model.createController(({ $bind, $widget, flowAPI, $w }) => {
  const warmupData = new WarmupDataManager(
    flowAPI.controllerConfig.wixCodeApi.window.warmupData,
    flowAPI.environment,
  );
  let itemsResponse: DraftItem[];
  const initGallery = async () => {
    // @ts-expect-error
    const { galleryId } = flowAPI.controllerConfig.config;
    const fastGalleryService = new FastGalleryService({
      viewMode: getViewMode(flowAPI),
      draftGalleryId: galleryId,
      httpClient: flowAPI.httpClient,
      warmupData,
    });

    itemsResponse = await fastGalleryService.getGalleryItemsInCurrentContext();

    const wixSDKitems = convertDraftItemToWixCodeItem(itemsResponse, flowAPI);

    $w('#fastGallerySlot').slot.items = wixSDKitems;

    $w('#fastGallerySlot').slot.onGetMoreItems(async() => {
      const moreItems = await fastGalleryService.getGalleryItemsInCurrentContext();
      const wixSDKmoreItems = convertDraftItemToWixCodeItem(moreItems, flowAPI);
      $w('#fastGallerySlot').slot.items = [...$w('#fastGallerySlot').slot.items, ...wixSDKmoreItems]
    });
  };

  const renderSEOTags = () => {
    const items = convertDraftItemsToSEOItems(itemsResponse);
    flowAPI.controllerConfig.wixCodeApi.seo.renderSEOTags({
      itemType: 'MEDIA_COMPONENT',
      itemData: { items },
    });
  };
  return {
    pageReady: async () => {
      await initGallery();
      if (flowAPI.experiments.enabled('specs.fastGallery.renderSEOTags')) {
        renderSEOTags();
      }
    },
    exports: {},
  };
});
